import React, { useEffect, useState } from 'react';

import './css/App.css';
import { WalletIcon, HomeIcon, FriendsIcon, TaskIcon, LogoIcon, ActivityIcon, AgeAccountIcon, PremiumIcon } from './i/icons';
import stringsEn from './lang/strings.en'; // Локализация для английского
import stringsRu from './lang/strings.ru'; // Локализация для русского

import NavigationMenu from './api/navigationMenu'; // Import the NavigationMenu component
import HomePage from './pages/homePage';
import TasksPage from './pages/tasksPage';
import FriendsPage from './pages/friendsPage';
import BgPage from './pages/bgPage';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState('home');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div className="App">
      {isLoading ? (
        <div className="loading-container">
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
          <div className="loading-bar"></div>
        </div>
      ) : (
        <>
          <NavigationMenu currentPage={currentPage} handlePageChange={handlePageChange} />
          {currentPage === 'home' && <HomePage />}
          {currentPage === 'tasks' && <TasksPage />}
          {currentPage === 'friends' && <FriendsPage />}
        </>
      )}
      <BgPage />
    </div>
  );
}

export default App;
