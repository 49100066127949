export function showCustomAlert(message) {
    const alertContainer = document.createElement('div');
    alertContainer.className = 'custom-alert';
    alertContainer.innerHTML = `
      <div class="custom-alert-content">
        <p>${message}</p>
        <span class="close-button">&times;</span> <!-- Add close button -->
      </div>
    `;
    document.body.appendChild(alertContainer);

    const closeButton = alertContainer.querySelector('.close-button');
    closeButton.addEventListener('click', () => {
      if (alertContainer.parentNode) {
        document.body.removeChild(alertContainer);
      }
    });

    setTimeout(() => {
      if (alertContainer.parentNode) {
        document.body.removeChild(alertContainer);
      }
    }, 3000);
}