import React, { useState } from 'react';
import '../css/App.css';
import referralSystem from '../api/referralSystem';
import { showCustomAlert } from '../api/customAlert';
import { ReactComponent as CopyIcon } from '../i/svg/copy.svg';
import axios from 'axios';


function FriendsPage() {
  const [referralLink, setReferralLink] = useState('');
  const createUser  = async () => {
    fetch('http://212.67.13.88:3000/users')
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));
  };

  const copyReferralLink = async () => {
    const generatedLink = await referralSystem.generateInviteURL(81181226);
    if (!generatedLink) {
      console.log('Generated link is none');
    } else {
      navigator.clipboard.writeText(generatedLink);
      showCustomAlert('Copied!');
    }
  };

  return (
    <div className="FriendsPage">
      <div className="header">
        <h1>Friends</h1>
        <p>You get 10% from all friends and another 2.5% from friends of friends.</p>
      </div>
        <div className="friends-list">
          <div className="friend-item">
            <div className="avatar">
              AK
            </div>
            <div className="friend-info">
              <span className="friend-name">idakki</span>
              <span className="invited-count">
                👨‍👩‍👦‍👦 +5
              </span>
            </div>
            <div className="total-points">
              50 +
            </div>
          </div>
        </div>
      <div className="button-container">
        <button className="invite-button" onClick={createUser}>Invate Friends</button>
        <button className="copy-button" onClick={copyReferralLink}>
          <span className="copy-icon">
            {<CopyIcon />}
          </span>
        </button>
      </div>
    </div>
  );
}

export default FriendsPage;