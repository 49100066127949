import initDatabase from './database';

const referralSystem = {
    // Function to generate a referral code
    generateReferralCode: async (userId) => {
        const db = await initDatabase();
        const user = await db.transaction('users', 'readonly').store.get(userId);
        const referralCode = `${userId}${Math.floor(Math.random() * 10000)}`;
        return referralCode;
      },
  
    // Function to generate an invite URL for a user
    generateInviteURL: async (userId) => {
        const db = await initDatabase();
        console.log(userId);
        const referralCode = await db.transaction('users', 'readonly').store.get(userId);
        const INVITE_URL = "https://t.me/tern0tbot/start";
        console.log(`${INVITE_URL}?startapp=${referralCode.referral_code}`);
        return `${INVITE_URL}?startapp=${referralCode.referral_code}`;
      },

    // Function to get referral code from invite URL
    getReferral: (inviteURL) => {
      const referralCode = inviteURL.split('=')[1];
      return referralCode;
    },
};
  
export default referralSystem;