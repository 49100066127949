import React from 'react';
import nextSvg from '../i/svg/next.svg';


const Task = ({ task, activeTab, selectedTask, setSelectedTask }) => {
  return (
    <li key={task.id} className="task-block">
      <div className="task-icon">
        <img src={`../i/svg/${task.icon}`}/>
      </div>
      <div className="task-info">
        <h2>{task.title}</h2>
        <p>+{task.points} BB</p>
      </div>
      <div className="task-arrow">
      <button
          className={`task-claim-button ${activeTab === 'wander' && task.id === selectedTask ? 'task-claim-button-active' : ''}`}
          onClick={() => {
            if (task.id === selectedTask && activeTab === 'wander') {
              setSelectedTask(null);
              console.log(`Button clicked for task with ID: ${task.id}`);
              console.log('Button text: Claim');
            } else {
              setSelectedTask(task.id);
            }
          }}
        >
          {activeTab === 'wander' && task.id === selectedTask ? (
            <span>Claim</span>
          ) : (
            <img src={nextSvg}/>
          )}
        </button>
      </div>
    </li>
  );
};

export default Task;