import React, { useState, useEffect, useRef } from 'react';
import BlackHole from './blackHole';

function NewButton() {
  const [buttonText, setButtonText] = useState('Start Farm');
  const [points, setPoints] = useState(0);
  const farmingIntervalRef = useRef(null);

  const handleClick = () => {
    
    if (buttonText === 'Start Farm') {
      console.log('Points claimed:', points);
      setPoints(0); 
      setButtonText('Claim in 1 min');
    } else if (buttonText === 'Claim in 1 min') {
      setButtonText('Start Farm');
    }
  };

  useEffect(() => {
    // Очистка интервала при изменении buttonText
    return () => {
      farmingIntervalRef.current = setInterval(() => {
        setPoints((prevPoints) => prevPoints + 0.0001); // Начисление поинтов
      }, 1000);
    };
  }, [buttonText]);

  useEffect(() => {
    // Таймер
    let timer = 10;
    let interval;

    if (farmingIntervalRef.current) {
      interval = setInterval(() => {
        timer--;
        if (timer <= 0) {
          clearInterval(interval);
          clearInterval(farmingIntervalRef.current);
          setButtonText('Start Farm');
        }
      }, 1000);
    } else {
      clearInterval(interval);
      timer = 10; // Сбрасываем таймер
    }

    return () => clearInterval(interval);
  }, [farmingIntervalRef.current]);

  return (
    <button
      className={`new-button ${buttonText === 'Claim in 1 min' ? 'grayed-out' : ''}`}
      onClick={handleClick}
    >
      {buttonText === 'Start Farm' ? `Claim W${points.toFixed(4)}` : buttonText} 
    </button>
  );
}

export default NewButton;