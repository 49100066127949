import React from 'react';
import { HomeIcon, TaskIcon, FriendsIcon } from '../i/icons';

const NavigationMenu = ({ currentPage, handlePageChange }) => {
  return (
    <nav className="navigation-menu">
      <button onClick={() => handlePageChange('home')} className={currentPage === 'home' ? 'active' : ''}>
        <HomeIcon />
        <span>Home</span>
      </button>
      <button onClick={() => handlePageChange('tasks')} className={currentPage === 'tasks' ? 'active' : ''}>
        <TaskIcon />
        <span>Tasks</span>
      </button>
      <button onClick={() => handlePageChange('friends')} className={currentPage === 'friends' ? 'active' : ''}>
        <FriendsIcon />
        <span>Friends</span>
      </button>
    </nav>
  );
};

export default NavigationMenu;