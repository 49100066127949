import React, { useState } from 'react';
import '../css/App.css';

import tasksData from '../data/tasks.json'; // Import the JSON file
import Task from './Task'; // Import the Task component

function TasksPage() {
  const [activeTab, setActiveTab] = useState('wander');
  const [selectedTask, setSelectedTask] = useState(null);

  const wanderTasks = tasksData.filter((task) => task.project === 'Wander');
  const partnersTasks = tasksData.filter((task) => task.project === 'Partners');
  const ambassadorTasks = tasksData.filter((task) => task.project === 'Ambassador');

  return (
    <div className="TasksPage">
      <div className='task-title'>
        <h1>Tasks</h1>
        <h3>Complete all time-limited tasks from this list to receive additional project points</h3>
      </div>
      
      <div className="tabs">
        <button className={activeTab === 'wander' ? 'active' : ''} onClick={() => setActiveTab('wander')}>
          Wander
        </button>
        <button className={activeTab === 'partners' ? 'active' : ''} onClick={() => setActiveTab('partners')}>
          Partners
        </button>
        <button className={activeTab === 'ambassador' ? 'active' : ''} onClick={() => setActiveTab('ambassador')}>
          Ambassador
        </button>
      </div>
      <ul>
        {activeTab === 'wander' &&
          wanderTasks.map((task) => (
            <Task
              key={task.id}
              task={task}
              activeTab={activeTab}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
            />
          ))}
        {activeTab === 'partners' &&
          partnersTasks.map((task) => (
            <Task
              key={task.id}
              task={task}
              activeTab={activeTab}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
            />
          ))}
        {activeTab === 'ambassador' &&
          ambassadorTasks.map((task) => (
            <Task
              key={task.id}
              task={task}
              activeTab={activeTab}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
            />
          ))}
      </ul>
    </div>
  );
}

export default TasksPage;