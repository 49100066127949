import React, { useEffect, useState } from 'react';

import '../css/App.css';
import BgPage from './bgPage';
import { WalletIcon, HomeIcon, FriendsIcon, TaskIcon, LogoIcon, ActivityIcon, AgeAccountIcon, PremiumIcon } from '../i/icons';

import { showCustomAlert } from '../api/customAlert';
import NewButton from '../api/newButton';
import initDatabase from '../api/database';

function HomePage(){
  const [username, setUsername] = useState('');

  const handleWalletClick = () => {
    showCustomAlert('The wallet will be here soon!');
  };

  return (
    <div className="HomePage">
      <div className="content">
        <div className="user-info">
          <div className="user-avatar">
            id
          </div>
          <p className="username">idakki</p>
          <div className="wallet-icon" onClick={handleWalletClick}>
            <WalletIcon />
          </div>
        </div>
        <div className="balance-container">
          <div className="balance">
            <h1 className="balance-text">111.111.111</h1>
          </div>
        </div>
        <div className="game-container">
          <div className="game-background">
            <div className="logo-icon">
              <LogoIcon />
            </div>
            <p className="soon-text">Soon</p>
          </div>
        </div>
        <div className="new-div">
          <NewButton />
        </div>
      </div>
      <BgPage />
    </div>
  );
}

export default HomePage;