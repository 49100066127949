import React from 'react';
import '../css/App.css';
import '../css/style_bg.css';

function bgPage() {
  return (
    <div className="bg">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </div>
  );
}

export default bgPage;